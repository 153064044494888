import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { Mail, Instagram } from 'react-feather';
import { Send } from '../components/svg/icons';
import { PokeMegRater } from '../components/svg/pokeMegIcons';
import { navigateTo } from "gatsby-link";

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

export default class ContactPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state
      })
    })
      .then(() => navigateTo(form.getAttribute("action")))
      .catch(error => alert(error));
  };

  render() {
    return (
      <Layout>
        <SEO title="Contact" />
        <div className="container mx-auto p-4">
          <div className="flex text-orange font-semibold text-5xl justify-start xs:text-3xl xs:justify-center">
            Cast us a line.
          </div>
          {/* Social Section */}
          <div className="flex flex-col mt-3">
            <div className="flex hover:text-opacity-40 text-black mt-2 mb-1">
                <a className="flex flex-row items-center" href="https://www.instagram.com/pokepossi/">
                    <Instagram className="mr-2"/>
                    pokepossi
                </a>
            </div>
            <div className="flex hover:text-opacity-40 text-black mt-1 mb-2">
                <a className="flex flex-row items-center" href="mailto: pokepossi@gmail.com">
                    <Mail className="mr-2"/>
                    pokepossi@gmail.com
                </a>
            </div>
            
            
            <form
              name="Contact"
              method="POST"
              action="/thank-you"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              onSubmit={this.handleSubmit}
            >
              <input type="hidden" name="contact-form" value="Contact" />
              <p hidden>
                <label>
                  Don’t fill this out:{" "}
                  <input name="bot-field" onChange={this.handleChange} />
                </label>
              </p>

              <div className="flex flex-col">
                <label htmlFor="name" className="text-sm">
                  Name
                  <br/>
                  <input 
                    type="text" 
                    name="name" 
                    id="name" 
                    className="w-full px-3 py-2 bg-white bg-opacity-30 border-2 border-black focus:ring-blue focus:border-blue focus:outline-none focus:bg-opacity-100 focus:text-blue"
                    placeholder="Poke Pal"
                    onChange={this.handleChange}
                  />
                </label>
              </div>

              <div className="flex flex-col">
                <label htmlFor="email" className="text-sm">
                  Email
                  <br/>
                  <input 
                    type="text" 
                    name="email" 
                    id="email" 
                    className="w-full px-3 py-2 bg-white bg-opacity-30 border-2 border-black focus:ring-blue focus:border-blue focus:outline-none focus:bg-opacity-100 focus:text-blue"
                    placeholder="pokepal@fishmail.yum"
                    onChange={this.handleChange}
                  />
                </label>
              </div>

              <div className="flex flex-col">
                <label htmlFor="message" className="text-sm">
                  Message
                  <br/>
                  <textarea 
                    name="message" 
                    id="message"
                    className="w-full group-hover:px-3 py-2 bg-white bg-opacity-30 border-2 border-black focus:ring-blue focus:border-blue focus:outline-none focus:bg-opacity-100 focus:text-blue"
                    rows="5"
                    placeholder="Suggestions:
                      You are pretty.
                      I love ginger too.
                      What the heck, why haven't you reviewed us yet?
                      Here is a gift card to our poke restaurant!"
                    onChange={this.handleChange}
                  />
                </label>
              </div>

              <div className="flex flex-row justify-end">
                <button 
                  type="submit"
                  className="cursor-pointer flex my-4 justify-center shadow-lg border-2 text-white bg-orange border-orange border-opacity-100 p-1 focus:outline-none focus:ring-1 focus:ring-orange focus:border-orange focus:bg-white focus:text-orange hover:bg-white hover:text-orange sm:text-sm"
                >
                  <span class="flex items-center">
                    <Send className="h-4 transform rotate-90 mr-1"></Send>
                    <span class="block truncate font-bold text-md">
                      GO FISH
                    </span>
                  </span>
                </button>
              </div>

            </form>
            

          </div>
          
          <div class="relative flex flex-row justify-start h-24 xs:h-5">
            <PokeMegRater className="absolute -top-12 h-40 xs:h-20"></PokeMegRater>
          </div>
          
        </div>
        {/* Donation section */}
        {/*
        <div className="flex flex-col mb-3">
          <div className="flex flex-row justify-start my-3 xs:justify-center">
            <div className="flex flex-row text-orange gap-2 font-semibold text-5xl xs:text-3xl items-center">
              <Heart className="fill-current h-6"></Heart>
              Feed the Fish
              <Heart className="fill-current h-6"></Heart>
            </div>
          </div>
          <div className="flex flex-col xs:flex-row justify-evenly">
            <div className="flex flex-row xs:flex-col gap-2 justify-center">
              <div className="flex flex-row justify-start">
                <button disabled={false} class="flex shadow justify-center border-2 bg-green text-white border-green border-opacity-0 p-1 cursor-default focus:outline-none focus:ring-1 focus:ring-green focus:border-green focus:bg-white focus:text-green hover:bg-white hover:text-green hover:border-opacity-100 sm:text-sm">
                  <span class="flex items-center">
                    <CashApp className="h-5 mr-1"></CashApp>
                    <span class="block truncate font-bold text-md">
                      PokeMeg
                    </span>
                  </span>
                </button>
              </div>
              <div className="flex flex-row justify-start">
                <button disabled={false} class="flex shadow justify-center border-2 bg-blue text-white border-blue border-opacity-0 p-1 cursor-default focus:outline-none focus:ring-1 focus:ring-blue focus:border-blue focus:bg-white focus:text-blue hover:bg-white hover:text-blue hover:border-opacity-100 sm:text-sm">
                  <span class="flex items-center">
                    <PayPal className="h-5 fill-current mr-1"></PayPal>
                    <span class="block truncate font-bold text-md">
                      PokeMeg
                    </span>
                  </span>
                </button>
              </div>
            </div>
            <div class="flex flex-row justify-end">
              <PokeMegRater className="h-40 xs:h-20 transform scale-x-flip"></PokeMegRater>
            </div>
          </div>
        </div>
        */}
      </Layout>
    );
  }
}